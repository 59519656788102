<template>
    <v-card class="mx-auto" max-width="600">
        <v-card-title class="justify-center">
            {{ $t("auth.resetPassword") }}
        </v-card-title>
        <v-card-text>
            <v-alert
                v-if="error"
                border="left"
                colored-border
                color="error"
                elevation="2"
            >
                {{ $t("auth.resetPasswordError") }}
            </v-alert>
            <v-form ref="form" @submit.prevent="onSubmit">
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            :label="$t('users.user.password')"
                            prepend-icon="mdi-lock-outline"
                            :append-icon="
                                showPassword
                                    ? 'mdi-eye-outline'
                                    : 'mdi-eye-off-outline'
                            "
                            @click:append="showPassword = !showPassword"
                            :rules="passwordRule"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="isLoading"
                        >
                            {{ $t("button.submit") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        token: {
            type: [String],
            required: true,
        },
        email: {
            type: [String],
            required: true,
        },
    },
    data() {
        return {
            password: "",
            showPassword: false,

            isLoading: false,
            error: false,
        };
    },

    computed: {
        ...mapState({
            passwordRule : (state) => state.utils.passwordRule,
        }),
    },

    watch: {
        password() {
            this.error = false;
        },
    },

    methods: {
        onSubmit() {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                this.errors = {};

                this.$store
                    .dispatch("auth/resetPassword", {
                        password: this.password,
                        token: this.token,
                        email: this.email,
                    })
                    .then(() => {
                        this.$router.push({ name: "Login" });
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.error = true;
                        this.$refs.form.validate();
                    });
            }
        },
    },
};
</script>
